import { AppContext } from "./context";
import { useContext, useEffect, useState } from "react";

export const useAppContext = () => {
  return useContext(AppContext);
};

interface Options {
  breakpoint?: number;
}

const DEFAULT_MOBILE_BREAKPOINT = 768;

export function useMobile(options: Options = {}): boolean {
  const breakpoint = options.breakpoint ?? DEFAULT_MOBILE_BREAKPOINT;
  const [isMobile, setIsMobile] = useState<boolean>(
    window.innerWidth < breakpoint
  );

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < breakpoint);
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
}
