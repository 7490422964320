import { useContext } from "react";
import { AppContext } from "./context";
import {
  Text,
  Button,
  Flex,
  HStack,
  Stat,
  StatGroup,
  StatNumber,
  VStack,
  useClipboard,
  useToast,
  Divider,
  Badge,
} from "@chakra-ui/react";
import React from "react";
import {
  createCheckoutSession,
  createManageSubscriptionSession,
  registerStripeCustomer,
} from "./api";
import { Subscription } from "./types";
import { datadogRum } from "@datadog/browser-rum";

const isMobileDevice = () =>
  /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const GOOGLE = {
  GAW: "_gcl_aw",
  GS: "_gcl_gs",
  AU: "_gcl_au",
};
const GOOGLE_QUERY_PARAMS = {
  GAW: "gaw",
  GS: "gs",
  AU: "au",
};

const getGoogleCookies = () => {
  return {
    [GOOGLE.GAW]: getCookie(GOOGLE.GAW),
    [GOOGLE.GS]: getCookie(GOOGLE.GS),
    [GOOGLE.AU]: getCookie(GOOGLE.AU),
  };
};

const getGoogleQueries = (query: URLSearchParams) => {
  return {
    [GOOGLE.GAW]: query.get(GOOGLE_QUERY_PARAMS.GAW),
    [GOOGLE.GS]: query.get(GOOGLE_QUERY_PARAMS.GS),
    [GOOGLE.AU]: query.get(GOOGLE_QUERY_PARAMS.AU),
  };
};

function getCookie(name: string): string | null {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  if (match) return match[2];
  return null;
}

const CustomBadge: React.FC = ({ children }) => (
  <Badge variant="solid" colorScheme="teal">
    {children}
  </Badge>
);

export const Home = () => {
  const { user, setUser } = useContext(AppContext);
  const toast = useToast();
  const { onCopy, setValue, hasCopied } = useClipboard("");

  const nbaSub = user?.subscriptions?.find((s) => s.type === "nba") || {
    tier: "free",
    type: "nba",
  };
  const nflSub = user?.subscriptions?.find((s) => s.type === "nfl") || {
    tier: "free",
    type: "nfl",
  };
  const mlbSub = user?.subscriptions?.find((s) => s.type === "mlb") || {
    tier: "free",
    type: "mlb",
  };
  const eplSub = user?.subscriptions?.find((s) => s.type === "epl") || {
    tier: "free",
    type: "epl",
  };

  React.useEffect(() => {
    if (user?.apiKey) {
      setValue(user?.apiKey);
    }
  }, [user?.apiKey, setValue]);

  // If stripe_session_id is set, the customer arrived here after successfully checking out.
  // We need to call an endpoint that will fetch the stripe customer based on the session_id and update
  // the users.stripe_customer_id column.
  const query = new URLSearchParams(window.location.search);
  const sessionId = query.get("stripe_session_id");
  // We set this in 'succes_url' for stripe redirect after successful checkout for google ad conversion tracking
  const purchasedTier = query.get("tier");
  const {
    [GOOGLE.AU]: gau,
    [GOOGLE.GAW]: gaw,
    [GOOGLE.GS]: gs,
  } = getGoogleQueries(query);

  const addCustomer = React.useCallback(
    async (sid: string) => {
      try {
        const user = await registerStripeCustomer({ stripeSessionId: sid });
        setUser(user);
      } catch (e) {
        console.log(e);
      }
    },
    [setUser]
  );
  React.useEffect(() => {
    if (purchasedTier || sessionId) {
      if (gaw) {
        document.cookie = `${GOOGLE.GAW}=${gaw}`;
      }
      if (gau) {
        document.cookie = `${GOOGLE.AU}=${gau}`;
      }
      if (gs) {
        document.cookie = `${GOOGLE.GS}=${gs}`;
      }
      const dollars = purchasedTier === "paid" ? 9.99 : 39.99;
      (window as any).gtag("event", "conversion", {
        send_to: "AW-16760575486/17bXCKTZhOQZEP6riLg-",
        value: dollars,
        currency: "USD",
        transaction_id: user?.email,
      });

      datadogRum.addAction("stripe_callback", {
        sessionId,
        purchasedTier,
        query: query.toString(),
        gclAw: gaw,
        gclAu: gau,
        gclGS: gs,
        cookie: document.cookie,
        getGoogleCookies: getGoogleCookies(),
        email: user?.email,
      });
    }

    if (sessionId) {
      addCustomer(sessionId);
      toast({
        title: "Tier Upgraded",
        description:
          "It may take a few minutes for changes to take effect. Please check back in a minute.",
        status: "success",
        isClosable: true,
      });

      if (!purchasedTier) {
        console.error("No tier found in callback query string");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, addCustomer, purchasedTier, gau, gaw, gs]);

  const onNewCustomer = async (
    tier: "paid" | "paid_plus",
    type: "nfl" | "nba" | "mlb" | "epl"
  ) => {
    const gCookies = getGoogleCookies();
    const url = await createCheckoutSession(
      tier,
      type,
      gCookies[GOOGLE.GAW],
      gCookies[GOOGLE.AU],
      gCookies[GOOGLE.GS]
    );

    if (isMobileDevice()) {
      window.location.href = url;
    } else {
      window.open(url);
    }
  };

  const subChange = query.get("stripe_subscription_change") === "true";
  React.useEffect(() => {
    if (subChange) {
      toast({
        title: "Account Changed",
        description: "It may take a few minutes for changes to take effect.",
        status: "success",
        isClosable: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subChange]);

  const onChangeSubscription = async () => {
    const url = await createManageSubscriptionSession();
    if (isMobileDevice()) {
      window.location.href = url;
    } else {
      window.open(url);
    }
  };

  const renderButtons = (sub: Subscription) => {
    return (
      <HStack>
        {sub.tier === "free" ? (
          <>
            <Button
              size="sm"
              variant="outline"
              colorScheme="teal"
              onClick={() => onNewCustomer("paid", sub.type)}
            >
              Upgrade to ALL-STAR
            </Button>
            <Button
              size="sm"
              variant="solid"
              colorScheme="teal"
              onClick={() => onNewCustomer("paid_plus", sub.type)}
            >
              Upgrade to GOAT
            </Button>
          </>
        ) : (
          <Button size="sm" colorScheme="red" onClick={onChangeSubscription}>
            Cancel Subscription
          </Button>
        )}
      </HStack>
    );
  };

  return (
    <Flex h="100%" my={4}>
      <Flex flex={2} />
      <Flex justify="center" align="center" h="100%">
        <VStack alignItems="flex-start" mb={6} h="100%">
          <StatGroup flexDir="column">
            <Stat>
              <CustomBadge>Email</CustomBadge>
              <StatNumber>{user?.email}</StatNumber>
            </Stat>
            <Stat my={5}>
              <HStack>
                <CustomBadge>API Key</CustomBadge>
                <Button size="xs" onClick={onCopy}>
                  {hasCopied ? "Copied!" : "Copy"}
                </Button>
              </HStack>
              <StatNumber>{user?.apiKey}</StatNumber>
            </Stat>
            <Stat>
              <CustomBadge>NBA Tier</CustomBadge>
              <StatNumber>
                {nbaSub.tier === "free"
                  ? "Rookie"
                  : nbaSub.tier === "paid"
                    ? "All-Star"
                    : "GOAT"}
                {renderButtons(nbaSub)}
              </StatNumber>
            </Stat>
            <Stat marginTop={4}>
              <CustomBadge>NFL Tier</CustomBadge>
              <StatNumber>
                {nflSub.tier === "free"
                  ? "Rookie"
                  : nflSub.tier === "paid"
                    ? "All-Star"
                    : "GOAT"}
                {renderButtons(nflSub)}
              </StatNumber>
            </Stat>
            <Stat marginTop={4}>
              <CustomBadge>MLB Tier</CustomBadge>
              <StatNumber>
                {mlbSub.tier === "free"
                  ? "Rookie"
                  : mlbSub.tier === "paid"
                    ? "All-Star"
                    : "GOAT"}
                {renderButtons(mlbSub)}
              </StatNumber>
            </Stat>
            <Stat marginTop={4}>
              <CustomBadge>EPL Tier</CustomBadge>
              <StatNumber>
                {eplSub.tier === "free"
                  ? "Rookie"
                  : eplSub.tier === "paid"
                    ? "All-Star"
                    : "GOAT"}
                {renderButtons(eplSub)}
              </StatNumber>
            </Stat>
          </StatGroup>
          <Divider />
          <Text>
            Canceling a subscription will end access immediately, not at the end
            of the billing period.
          </Text>
        </VStack>
      </Flex>
      <Flex flex={2} />
    </Flex>
  );
};
