import axios from "axios";
import { User } from "./types";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST || "http://localhost:8080/",
  withCredentials: true,
});

export const getSession = async (): Promise<User> => {
  const res = await instance.get("/session");
  return res.data;
};

export const signIn = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const res = await instance.post("/signin", { email, password });
  return res.data;
};

export const resetPassword = async ({ email }: { email: string }) => {
  const res = await instance.post("/reset-password", { email });
  return res.data;
};

export const changePassword = async ({
  token,
  password,
}: {
  token: string;
  password: string;
}) => {
  const res = await instance.post("/change-password", { token, password });
  return res.data;
};

export const signUp = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const res = await instance.post("/signup", { email, password });
  return res.data;
};

export const signOut = async () => {
  const res = await instance.post("/signout");
  return res.data;
};

export const createCheckoutSession = async (
  tier: "paid" | "paid_plus",
  type: "nba" | "nfl" | "mlb" | "epl",
  gaw?: string | null,
  gau?: string | null,
  ggs?: string | null
) => {
  const res = await instance.post(
    `/stripe/create-checkout-session?tier=${tier}&type=${type}&gaw=${gaw}&gau=${gau}&ggs=${ggs}`,
    {
      google: {
        gaw,
        gau,
        ggs,
      },
    }
  );
  return res.data;
};

// Fills the stripe_customer_id on the users table
export const registerStripeCustomer = async ({
  stripeSessionId,
}: {
  stripeSessionId: string;
}) => {
  const res = await instance.post<User>("/stripe/users/customer", {
    sessionId: stripeSessionId,
  });
  return res.data;
};

export const createManageSubscriptionSession = async () => {
  const res = await instance.post("/stripe/create-portal-session");
  return res.data;
};
