import { useContext } from "react";
import { AppContext } from "./context";
import {
  Text,
  Button,
  Flex,
  HStack,
  VStack,
  useClipboard,
  useToast,
  Badge,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Avatar,
  Grid,
  GridItem,
  CardFooter,
} from "@chakra-ui/react";
import React from "react";
import {
  createCheckoutSession,
  createManageSubscriptionSession,
  updateTier,
} from "./api";
import { Tier, Type } from "./types";
import { datadogRum } from "@datadog/browser-rum";
import { useMobile } from "./hooks";

import { ReactComponent as Baseball } from "./assets/baseball.svg";
import { ReactComponent as Soccer } from "./assets/soccer.svg";
import { ReactComponent as Football } from "./assets/football.svg";
import { ReactComponent as Basketball } from "./assets/basketball.svg";
import { ReactComponent as AllBalls } from "./assets/allballs.svg";

const isMobileDevice = () =>
  /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const GOOGLE = {
  GAW: "_gcl_aw",
  GS: "_gcl_gs",
  AU: "_gcl_au",
};
const GOOGLE_QUERY_PARAMS = {
  GAW: "gaw",
  GS: "gs",
  AU: "au",
};

const getGoogleCookies = () => {
  return {
    [GOOGLE.GAW]: getCookie(GOOGLE.GAW),
    [GOOGLE.GS]: getCookie(GOOGLE.GS),
    [GOOGLE.AU]: getCookie(GOOGLE.AU),
  };
};

const getGoogleQueries = (query: URLSearchParams) => {
  return {
    [GOOGLE.GAW]: query.get(GOOGLE_QUERY_PARAMS.GAW),
    [GOOGLE.GS]: query.get(GOOGLE_QUERY_PARAMS.GS),
    [GOOGLE.AU]: query.get(GOOGLE_QUERY_PARAMS.AU),
  };
};

function getCookie(name: string): string | null {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  if (match) return match[2];
  return null;
}

const CustomBadge: React.FC = ({ children }) => (
  <Badge variant="solid" colorScheme="teal">
    {children}
  </Badge>
);

export const Home = () => {
  const { user } = useContext(AppContext);
  const toast = useToast();
  const { onCopy, setValue, hasCopied } = useClipboard("");
  const isSmallScreen = useMobile();

  const nbaSub = user?.subscriptions?.find((s) => s.type === "nba") || {
    tier: "free",
    type: "nba",
  };
  const nflSub = user?.subscriptions?.find((s) => s.type === "nfl") || {
    tier: "free",
    type: "nfl",
  };
  const mlbSub = user?.subscriptions?.find((s) => s.type === "mlb") || {
    tier: "free",
    type: "mlb",
  };
  const eplSub = user?.subscriptions?.find((s) => s.type === "epl") || {
    tier: "free",
    type: "epl",
  };
  const allSub = user?.subscriptions?.find((s) => s.type === "all_access") || {
    tier: "free",
    type: "all_access",
  };

  const [selected, setSelected] = React.useState<{
    tier: Tier;
    type: Type;
  } | null>(null);
  const [isLoading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (user?.apiKey) {
      setValue(user?.apiKey);
    }
  }, [user?.apiKey, setValue]);

  // If stripe_session_id is set, the customer arrived here after successfully checking out.
  // We need to call an endpoint that will fetch the stripe customer based on the session_id and update
  // the users.stripe_customer_id column.
  const query = new URLSearchParams(window.location.search);
  const sessionId = query.get("stripe_session_id");
  // We set this in 'succes_url' for stripe redirect after successful checkout for google ad conversion tracking
  const purchasedTier = query.get("tier");
  const {
    [GOOGLE.AU]: gau,
    [GOOGLE.GAW]: gaw,
    [GOOGLE.GS]: gs,
  } = getGoogleQueries(query);

  React.useEffect(() => {
    if (purchasedTier || sessionId) {
      if (gaw) {
        document.cookie = `${GOOGLE.GAW}=${gaw}`;
      }
      if (gau) {
        document.cookie = `${GOOGLE.AU}=${gau}`;
      }
      if (gs) {
        document.cookie = `${GOOGLE.GS}=${gs}`;
      }
      const dollars = purchasedTier === "paid" ? 9.99 : 39.99;
      (window as any).gtag("event", "conversion", {
        send_to: "AW-16760575486/17bXCKTZhOQZEP6riLg-",
        value: dollars,
        currency: "USD",
        transaction_id: user?.email,
      });

      datadogRum.addAction("stripe_callback", {
        sessionId,
        purchasedTier,
        query: query.toString(),
        gclAw: gaw,
        gclAu: gau,
        gclGS: gs,
        cookie: document.cookie,
        getGoogleCookies: getGoogleCookies(),
        email: user?.email,
      });
    }

    if (sessionId) {
      toast({
        title: "Tier Upgraded",
        description:
          "It may take a few minutes for changes to take effect. Please check back in a minute.",
        status: "success",
        isClosable: true,
      });

      if (!purchasedTier) {
        console.error("No tier found in callback query string");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, purchasedTier, gau, gaw, gs]);

  const onNewCustomer = async (tier: Tier, type: Type) => {
    const gCookies = getGoogleCookies();
    const url = await createCheckoutSession(
      tier,
      type,
      gCookies[GOOGLE.GAW],
      gCookies[GOOGLE.AU],
      gCookies[GOOGLE.GS]
    );

    if (isMobileDevice()) {
      window.location.href = url;
    } else {
      window.open(url);
    }
  };

  const onManagePaymentDetails = async () => {
    const url = await createManageSubscriptionSession();
    if (isMobileDevice()) {
      window.location.href = url;
    } else {
      window.open(url);
    }
  };

  const modalContent = () => {
    if (!selected) {
      return null;
    }

    if (selected.tier === "free") {
      return "Cancellation will take effect immediately.";
    }

    if (selected.type === "all_access") {
      return "Upgrade to ALL-ACCESS ($89.99) to get access to all endpoints for every sport. This will cancel all other active subscriptions immediately.";
    }

    const currTier = user?.subscriptions?.find(
      (s) => s.type === selected.type
    )?.tier;
    if (selected.tier === "paid+") {
      return "Upgrade to GOAT ($39.99) to get access to all endpoints for this sport.";
    }

    if (selected.tier === "paid" && currTier === "free") {
      return "Upgrade to ALL-STAR ($9.99) to get access to more endpoints for this sport.";
    }

    if (selected.tier === "paid" && currTier === "paid+") {
      return "Downgrade to ALL-STAR ($9.99) to get access to fewer endpoints for this sport.";
    }
  };

  return (
    <Flex h="100%" my={4} w="100%" alignItems="center" justify="center">
      <VStack
        alignItems="center"
        mb={6}
        h="100%"
        gap={6}
        w="100%"
        px={isSmallScreen ? undefined : 36}
      >
        <VStack gap={2}>
          <Avatar name={user?.email} />
          <Text>{user?.email}</Text>
          <HStack>
            <CustomBadge>API Key</CustomBadge>
            <Button size="xs" onClick={onCopy}>
              {hasCopied ? "Copied!" : "Copy"}
            </Button>
            <Text>{user?.apiKey}</Text>
          </HStack>
          {user?.stripeCustomerId && (
            <Button
              colorScheme="teal"
              w="100%"
              onClick={() => onManagePaymentDetails()}
            >
              Manage Payment Details
            </Button>
          )}
        </VStack>
        {allSub?.tier === "paid+" ? (
          <AllAccessCard
            tier="paid+"
            onClick={(tier) => setSelected({ tier, type: "all_access" })}
          />
        ) : (
          <>
            <Grid
              templateRows={isSmallScreen ? "repeat(4, 1fr)" : "repeat(2, 1fr)"}
              templateColumns={
                isSmallScreen ? "repeat(1, 1fr)" : "repeat(2, 1fr)"
              }
              gap={4}
              w="100%"
            >
              <GridItem rowSpan={1} colSpan={1}>
                <PlanCard
                  tier={nbaSub.tier}
                  type="nba"
                  onClick={(tier) => setSelected({ tier, type: "nba" })}
                />
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <PlanCard
                  tier={eplSub.tier}
                  type="epl"
                  onClick={(tier) => setSelected({ tier, type: "epl" })}
                />
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <PlanCard
                  tier={nflSub.tier}
                  type="nfl"
                  onClick={(tier) => setSelected({ tier, type: "nfl" })}
                />
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <PlanCard
                  tier={mlbSub.tier}
                  type="mlb"
                  onClick={(tier) => setSelected({ tier, type: "mlb" })}
                />
              </GridItem>
            </Grid>
            <AllAccessCard
              tier="free"
              onClick={(tier) => setSelected({ tier, type: "all_access" })}
            />
          </>
        )}
      </VStack>

      <Modal isOpen={!!selected} onClose={() => setSelected(null)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Change{" "}
            {selected?.type === "all_access"
              ? "ALL-ACCESS"
              : selected?.type.toUpperCase()}{" "}
            Plan
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>{modalContent()}</ModalBody>

          <ModalFooter>
            <HStack justify="space-between" w="100%">
              <Button
                mr={3}
                variant="ghost"
                onClick={() => setSelected(null)}
                isLoading={isLoading}
              >
                Close
              </Button>
              <Button
                variant="solid"
                colorScheme="teal"
                isLoading={isLoading}
                onClick={async () => {
                  setLoading(true);

                  try {
                    if (user?.stripeCustomerId) {
                      await updateTier(selected!.tier, selected!.type);
                    } else {
                      await onNewCustomer(selected!.tier, selected!.type);
                    }
                    toast({
                      title: "Account Changed",
                      description:
                        "It may take a few minutes for changes to take effect.",
                      status: "success",
                      isClosable: true,
                    });
                  } catch (e) {
                    toast({
                      title: "Error",
                      description: user?.stripeCustomerId
                        ? "Manage your payment details and ensure a default payment method is selected."
                        : "Please contact hello@balldontlie.io for support.",
                      status: "error",
                      isClosable: true,
                      duration: null,
                    });
                  }

                  setLoading(false);
                  setSelected(null);
                }}
              >
                Confirm
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

const AllAccessCard = ({
  tier,
  onClick,
}: {
  tier: Tier;
  onClick: (tier: Tier) => void;
}) => {
  const isSmallScreen = useMobile();
  const content =
    tier === "free" ? (
      <Button
        colorScheme="teal"
        variant="solid"
        w="100%"
        onClick={() => onClick("paid+")}
      >
        Upgrade to ALL-ACCESS
      </Button>
    ) : (
      <Button variant="outline" w="100%" onClick={() => onClick("free")}>
        Cancel Subscription
      </Button>
    );
  return (
    <Card
      w={isSmallScreen ? "100%" : "50%"}
      _hover={{
        borderWidth: "0.1px",
        borderColor: "teal.500",
      }}
    >
      <CardHeader fontWeight="bold">
        <Flex justify="space-between" alignItems="center">
          <Flex alignItems="center" gap={2}>
            ALL-ACCESS
            <AllBalls />
          </Flex>
          {tier === "free" ? (
            <Badge>TIER: FREE</Badge>
          ) : (
            <Text>You have access to everything</Text>
          )}
        </Flex>
      </CardHeader>
      <CardBody w="100%">
        <Text>
          Purchase a single subscription for $89.99 and get access to every
          endpoint across all sports.
        </Text>
      </CardBody>
      <CardFooter>{content}</CardFooter>
    </Card>
  );
};

const PlanCard = ({
  tier,
  type,
  onClick,
}: {
  tier: Tier;
  type: Type;
  onClick: (tier: Tier) => void;
}) => {
  let content;
  if (tier === "free") {
    content = (
      <>
        <Button
          variant="outline"
          colorScheme="teal"
          w="100%"
          onClick={() => onClick("paid")}
        >
          Upgrade to ALL-STAR
        </Button>
        <Button
          variant="solid"
          colorScheme="teal"
          w="100%"
          onClick={() => onClick("paid+")}
        >
          Upgrade to GOAT
        </Button>
      </>
    );
  } else if (tier === "paid") {
    content = (
      <>
        <Button
          variant="solid"
          colorScheme="teal"
          w="100%"
          onClick={() => onClick("paid+")}
        >
          Upgrade to GOAT
        </Button>
        <Button variant="outline" w="100%" onClick={() => onClick("free")}>
          Cancel Subscription
        </Button>
      </>
    );
  } else {
    content = (
      <>
        <Button
          colorScheme="teal"
          variant="outline"
          w="100%"
          onClick={() => onClick("paid")}
        >
          Downgrade to ALL-STAR
        </Button>
        <Button variant="outline" w="100%" onClick={() => onClick("free")}>
          Cancel Subscription
        </Button>
      </>
    );
  }

  let icon;
  if (type === "nba") {
    icon = <Basketball />;
  } else if (type === "nfl") {
    icon = <Football />;
  } else if (type === "mlb") {
    icon = <Baseball />;
  } else if (type === "epl") {
    icon = <Soccer />;
  } else {
    // Should never get to this case
    icon = <AllBalls />;
  }

  return (
    <Card
      w="100%"
      _hover={{
        borderWidth: "0.1px",
        borderColor: "teal.500",
      }}
    >
      <CardHeader fontWeight="bold">
        <Flex justify="space-between" alignItems="center">
          <Flex alignItems="center" gap={2}>
            {type.toUpperCase()}
            {icon}
          </Flex>
          <Badge>
            TIER:{" "}
            {tier === "free" ? "FREE" : tier === "paid" ? "ALL-STAR" : "GOAT"}
          </Badge>
        </Flex>
      </CardHeader>
      <CardBody w="100%">
        <VStack gap={2}>{content}</VStack>
      </CardBody>
    </Card>
  );
};
